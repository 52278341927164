:root {
  color-scheme: only light;
  --safe-area-top: 0px;
  --safe-area-bottom: 0px;
  --padding-top: 25px;
  --padding-top-page: calc(var(--padding-top) + var(--safe-area-top));

  --white: #ffffff;
  --black: #000000;
  --primary-text-color: #051c2c;
  --primary-pink-color: #ff64ab;
  --primary-pink-color-hover: #fc4c9c;
  --primary-blue-color: #29acbe;
  --secondary-blue-color: #00779c;
  --btn-not-found-hover: #198fb4;

  --bg-tablet-desktop-color: #fdffff;
  --bg-gradient-not-found-page: linear-gradient(180deg, #45b3db 0%, #7bc9e6 12%, #f4fcff 100%);
  --bg-color-overlay-loading-display: rgba(222, 243, 252, 0.302);
  --bg-wrapper-float-btn: transparent
    linear-gradient(180deg, #ffffff00 0%, #ffffff67 15%, #fffffff0 100%) 0% 0% no-repeat padding-box;

  /* shadow */
  --shadow-main-buttons: inset -1px -3px 6px #ffffff1f, 1px 3px 6px #00000033;

  /* fonts */
  --primary-font-family: ui-rounded, BlinkMacSystemFont, 'Segoe UI', Roboto, Arial, system-ui,
    sans-serif;

  /* transition */
  --primary-transition-time-and-func: 250ms cubic-bezier(1, 0.345, 0.34, 0.595);
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.swiper * {
  box-sizing: border-box !important;
}

html,
body {
  border: 0;
  height: 100vh;
}

.no-scroll {
  overflow: hidden;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

html,
body,
ul,
ol {
  padding: 0;
}

html,
body,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a {
  text-decoration-skip-ink: auto;
}

a:hover {
  text-decoration: none;
}

/* Common scroll styles for WebKit (Chrome, Safari) and Blink (Opera) */
::-webkit-scrollbar {
  max-width: 8px;
  max-height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #93999b;
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background-color: #cfd1d2;
}

/* Styles scroll for Edge and Internet Explorer */
body {
  scrollbar-face-color: #93999b;
  scrollbar-track-color: #cfd1d2;
  scrollbar-arrow-color: #333;
}

/* Styles scroll for Firefox */
html {
  scrollbar-color: #93999b #cfd1d2;
}

body {
  scrollbar-width: thin;
}

body::-webkit-scrollbar {
  width: thin;
}

body::-webkit-scrollbar-thumb {
  background-color: #93999b;
  border-radius: 6px;
}

body::-webkit-scrollbar-track {
  background-color: #cfd1d2;
}

body {
  font-family: var(--primary-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  color: var(--primary-text-color);
  background-color: var(--white);
  -webkit-tap-highlight-color: transparent;
}

input,
button,
select,
textarea,
pre {
  font: inherit;
}

.container {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media screen and (min-width: 430px) {
  .container {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media screen and (min-width: 768px) {
  body {
    background-color: var(--bg-tablet-desktop-color);
  }

  .container {
    padding-left: 35px;
    padding-right: 35px;
  }
}

@media screen and (min-width: 1280px) {
  .container {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media screen and (min-width: 1920px) {
  .container {
    padding-left: 70px;
    padding-right: 70px;
  }
}

@media screen and (min-width: 2560px) {
  .container {
    padding-left: calc((80 / 2560) * 100vw);
    padding-right: calc((80 / 2560) * 100vw);
  }
}

.custom-scrollbar--blue,
.custom-scrollbar--green {
  overflow-y: auto;
  scrollbar-width: thin;
}

.custom-scrollbar--blue::-webkit-scrollbar-thumb,
.custom-scrollbar--green::-webkit-scrollbar-thumb {
  border-radius: 6px;
}

.custom-scrollbar--blue::-webkit-scrollbar,
.custom-scrollbar--green::-webkit-scrollbar {
  max-width: 6px;
  max-height: 5px;
}

.custom-scrollbar--blue::-webkit-scrollbar-track,
.custom-scrollbar--green::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: #e5eaed26;
}

.custom-scrollbar--blue {
  scrollbar-color: #beebf9 #f3f7f9;
}

.custom-scrollbar--blue::-webkit-scrollbar-thumb {
  background: #c5ecf8;
}

.custom-scrollbar--green {
  scrollbar-color: #0c5a4a #f3f7f9;
}

.custom-scrollbar--green::-webkit-scrollbar-thumb {
  background: #0c5a4a;
}

.carousel-container {
  overflow-x: auto;
}

@media screen and (min-width: 768px) {
  .carousel-container::-webkit-scrollbar-thumb {
    background: transparent;
  }

  .carousel-container::-webkit-scrollbar-track {
    background: transparent;
  }

  .carousel-container:hover::-webkit-scrollbar-thumb {
    background: #c5ecf8;
  }

  .custom-scrollbar--green.carousel-container:hover::-webkit-scrollbar-thumb {
    background: #347e6e;
  }

  .carousel-container:hover::-webkit-scrollbar-track {
    background: #e5eaed26;
  }

  .custom-scrollbar--green.carousel-container:hover::-webkit-scrollbar-track {
    background: #abdbd14d;
  }
}

@media screen and (max-width: 767px) {
  .carousel-container {
    -webkit-overflow-scrolling: touch; /* iOS momentum scrolling */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .carousel-container::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 1279px) {
  .carousel-container {
    -webkit-overflow-scrolling: touch;
  }
}

@media screen and (min-width: 1280px) {
  .custom-scrollbar--blue::-webkit-scrollbar,
  .custom-scrollbar--green::-webkit-scrollbar {
    max-height: 8px;
  }
}

body.camera-active {
  background-color: transparent !important;
}

.hide-tab-bar {
  display: none;
}
