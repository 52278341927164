@import 'styles/_variables';
@import 'styles/_functions';

.lottie-btn {
  position: absolute;
  top: 50%;
  left: -40px;
  width: 30px;
  height: 30px;
  transform: translateY(-50%);

  @media screen and (min-width: 1280px) {
    width: responsive-scale(30, 50, $scale-between-1280-and-2560);
    height: responsive-scale(30, 50, $scale-between-1280-and-2560);
    left: responsive-scale(-40, -70, $scale-between-1280-and-2560);
  }

  @media screen and (min-width: 2560px) {
    left: px-to-vw(-70, 2560);
    width: px-to-vw(50, 2560);
    height: px-to-vw(50, 2560);
  }
}

.page-wrapper-with-navigation,
.page-wrapper-without-navigation {
  padding-top: var(--padding-top-page);
}

.page-wrapper-with-navigation {
  @media screen and (min-width: 768px) {
    padding-top: 21px;
  }

  @media screen and (min-width: 1280px) {
    padding-top: responsive-scale(22, 40, $scale-between-1280-and-2560);
  }

  @media screen and (min-width: 1920px) {
    padding-top: responsive-scale(29, 45, $scale-between-1920-and-2560);
  }

  @media screen and (min-width: 2560px) {
    padding-top: px-to-vw(40, 2560);
  }
}

.page-wrapper-without-navigation {
  @media screen and (min-width: 768px) {
    padding-top: 31px;
  }

  @media screen and (min-width: 1280px) {
    padding-top: responsive-scale(32, 35, $scale-between-1280-and-1920);
  }

  @media screen and (min-width: 1920px) {
    padding-top: responsive-scale(33, 45, $scale-between-1920-and-2560);
  }

  @media screen and (min-width: 2560px) {
    padding-top: px-to-vw(43, 2560);
  }
}

.page-wrapper-padding-with-footer {
  padding-bottom: 30px;
}

.page-wrapper-padding-bottom-mob {
  padding-bottom: 70px;
}

.page-wrapper-padding-bottom-with-tab-bar-web {
  padding-bottom: 125px; // 75 + 50
}

.page-wrapper-padding-bottom-with-tab-bar-mob-home {
  padding-bottom: 100px; // 85 + 15
}

.page-wrapper-padding-bottom-with-tab-bar-mob {
  padding-bottom: 135px; // 85 + 50
}

.page-wrapper-padding-with-footer,
.page-wrapper-padding-bottom-with-tab-bar-mob,
.page-wrapper-padding-bottom-with-tab-bar-web {
  @media screen and (min-width: 768px) {
    padding-bottom: 40px;
  }

  @media screen and (min-width: 1280px) {
    padding-bottom: responsive-scale(40, 70, $scale-between-1280-and-2560);
  }

  @media screen and (min-width: 2560px) {
    padding-bottom: px-to-vw(70, 2560);
  }
}

.header-flex {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    align-items: center;
  }
}

.logo {
  @media screen and (min-width: 1280px) {
    position: relative;
    left: -20px;
  }

  @media screen and (min-width: 1920px) {
    left: -25px;
  }

  @media screen and (min-width: 2560px) {
    left: px-to-vw(-35, 2560);
  }
}

.closeBtn,
.closeBtn--mobile-only {
  width: 20px;
  height: 20px;
  flex-shrink: 0;

  & > svg {
    width: 100%;
    height: 100%;
  }
}

.closeBtn {
  display: block;

  @media screen and (min-width: 1280px) {
    width: responsive-scale(20, 30, $scale-between-1280-and-2560);
    height: responsive-scale(20, 30, $scale-between-1280-and-2560);
  }

  @media screen and (min-width: 2560px) {
    width: px-to-vw(30, 2560);
    height: px-to-vw(30, 2560);
  }
}

.closeBtn--mobile-only {
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.tooltip {
  visibility: hidden;
  opacity: 0;
  transition:
    opacity 0.2s ease,
    visibility 0s ease 0.2s;
}

.btn-show-tooltip:hover .tooltip {
  @media screen and (min-width: 768px) {
    visibility: visible;
    opacity: 1;
    transition:
      opacity 0.2s ease,
      visibility 0s ease 0s;
  }
}

//search, result, analyse, favorite
.header-wrapper-mb {
  margin-bottom: 20px;

  @media screen and (min-width: 430px) {
    margin-bottom: responsive-scale(25, 35, $scale-between-430-and-768);
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 35px;
  }

  @media screen and (min-width: 1280px) {
    margin-bottom: responsive-scale(30, 60, $scale-between-1280-and-2560);
  }

  @media screen and (min-width: 2560px) {
    margin-bottom: px-to-vw(60, 2560);
  }
}

// favorite, result, product search
.page-title-size-v1 {
  font-size: responsive-scale(19, 23, $scale-between-320-and-390);

  @media screen and (min-width: 390px) {
    font-size: responsive-scale(23, 30, $scale-between-390-and-768);
  }

  @media screen and (min-width: 768px) {
    font-size: responsive-scale(30, 32, $scale-between-768-and-1280);
  }

  @media screen and (min-width: 1280px) {
    font-size: responsive-scale(32, 50, $scale-between-1280-and-2560);
  }

  @media screen and (min-width: 2560px) {
    font-size: px-to-vw(50, 2560);
  }
}

.reset-btn {
  padding: 0px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.wrapper-float-btn {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 100px;
  padding-top: 11px;
  background: var(--bg-wrapper-float-btn);
  z-index: 5;

  @media screen and (min-width: 768px) {
    min-height: 110px;
  }

  @media screen and (min-width: 1280px) {
    min-height: auto;
    padding-top: responsive-scale(11, 44, $scale-between-1280-and-2560);
    height: responsive-scale(110, 200, $scale-between-1280-and-2560);
  }

  @media screen and (min-width: 2560px) {
    padding-top: px-to-vw(44, 2560);
    height: px-to-vw(200, 2560);
  }
}

.wrapper-float-btn-padding-web {
  padding-bottom: calc(env(safe-area-inset-bottom) + 35px);
}

.wrapper-float-btn-padding-mob {
  padding-bottom: 50px;
}
